import { Modal, Button } from "antd";
import { useRef, useEffect, useState } from "react";
import axios from "../../services/Api";
import moment from "moment";
import formatMoney from '../../components/ConvertMoney';
import printJS from 'print-js';  // Import print-js for printing
import { REACT_APP_API_KEY_IMAGE } from "../../constant/data";
import logo from '../../assets/logowhite.png';
import { useDispatch } from "react-redux";
import { getSupplierInfo } from "../../reducers/supplierReducer";
import "./Invoice.css";  // Custom CSS for styling

const Invoice = ({ show, id, handleCancel }) => {
const [images, setImages] = useState('');
const [inforShop, setInforShop] = useState({});
const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);

  useEffect(() => {
    if (id) {
      fetchInvoiceData(id);
    }
  }, [id]);
  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
};

  const fetchInvoiceData = async (id) => {
    try {
      const response = await axios.get(`/bill/get-id?id=${id}`);
      const data = response.data.data;
      setInvoiceData({
        customerName: data.customerName,
        phone: data.phone,
        address: data.address,
        invoiceNo: data.invoiceNo,
        createdDate: moment(data.createdDate).format("DD-MM-YYYY"),
        totalInit: data.initialPrice,
        total: data.totalMoney,
        tax: data.tax,
      });
      setInvoiceItems(data.billDetails);
    } catch (error) {
      console.error("Error fetching invoice data:", error);
    }
  };

  const handlePrint = () => {
    printJS({
      printable: componentRef.current,
      type: "html",
      css: "./Invoice.css",
      targetStyles: ["*"],
    });
  };

  const getSupplier = () => {
        dispatch(
            getSupplierInfo({}, {
                onSuccess: (res) => {
                    if (res.data.data?.data?.length > 0) {
                        const data = res.data?.data?.data[0];
                        setInforShop(data)
                        console.log(data)
                        setImages(data.image === null ? images : data?.image);
                    }
                },
            })
        );
    };

    useEffect(() => {
        getSupplier()
    }, []);

  return (
    <Modal
      width="70%"
      title="Invoice"
      open={show}
      onCancel={handleCancel}
      footer={null}
    >
      <div className="invoice-box" ref={componentRef}>
        {/* Header */}
        <div className="invoice-header">
          <div className="company-info">
            <img
                src={images ? `${REACT_APP_API_KEY_IMAGE}${images}` : logo}
                style={{ width: 'auto', height: 60 }}
                alt="Logo"
            />
            <h2>{inforShop?.name}</h2>
            <p style={{minWidth:"max-content"}}>Địa chỉ: {inforShop?.province}, {inforShop?.district}, {inforShop?.ward}, {inforShop?.address}</p>
            <p>Phone: {inforShop?.phone}</p>
          </div>
          <div className="invoice-details">
            <h1>Hoá đơn</h1>
            <p style={{minWidth:"200px"}}>Ngày tạo: {invoiceData.createdDate}</p>
          </div>
        </div>

        {/* Customer Info */}
        <div className="invoice-customer">
          <h3>Thông tin khách hàng:</h3>
          <p>Tên khách hàng: <strong>{invoiceData.customerName}</strong></p>
          <p>Số điện thoại: {invoiceData.phone}</p>
        </div>

        {/* Invoice Items */}
        <table className="invoice-table">
          <thead>
            <tr>
            <th>Tên dịch vụ</th>
            <th>Giá dịch vụ</th>
            <th>Nhân viên phục vụ</th>
            <th>Thời gian bắt đầu</th>
            <th>Thời gian kết thúc</th>
            <th>Mã giảm giá</th>
            <th>Số tiền giảm</th>
            </tr>
          </thead>
          <tbody>
            {invoiceItems.map((item, index) => (
              <tr key={index}>
                <td>{item.service}</td>
                <td><b>{FormatMoney(item.price)} VND</b></td>
                <td>{item.employee}</td>
                <td>{moment(item.realTime).format('DD/MM/YYYY - HH:mm:ss')}</td>
                <td>{moment(item.endTime).format('DD/MM/YYYY - HH:mm:ss')}</td>
                <td>{item.voucherCode}</td>
                <td>{FormatMoney(item.discountVoucher)} VND</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Totals */}
        <div className="invoice-summary">
          <p>
            <strong>Tổng cộng : </strong> {invoiceData.totalInit?.toLocaleString()} VND
          </p>
          <p>
            <strong>Thuế ({invoiceData.tax}%): </strong> 
            {((invoiceData.total * (invoiceData.tax / 100)) || 0).toFixed(2)}
          </p>
          <p>
            <strong>Tổng thanh toán: </strong> <strong>{invoiceData.total?.toLocaleString()}</strong> VND
          </p>
        </div>
      </div>

      {/* Actions */}
      <div className="invoice-actions">
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" onClick={handlePrint}>
          Print
        </Button>
      </div>
    </Modal>
  );
};

export default Invoice;
