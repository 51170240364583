import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Popover,
  Row,
  Table,
  Tooltip,
} from "antd";
import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
  ScheduleOutlined,
  TableOutlined,
} from "@ant-design/icons";

import formatMoney from "../../components/ConvertMoney";
import CustomSelect from "../../constant/Select";
import BookingSelect from "../../constant/selectBooking";
import { addBooking, updateBookingDetail } from "../../reducers/bookingReducer";
import axios from "../../services/Api";
import "./Booking.css";

import moment from "moment";

const UpdateBooking = () => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();
  const [formCustomer] = Form.useForm();
  const [formBooking] = Form.useForm();
  const [form] = Form.useForm();
  const id = window.location.href.split("booking-detail/")[1];
  const [employee, setEmployee] = useState([]);

  const supplierId = localStorage.getItem("supplierId");

  const [userId, setUserId] = useState(0);
  const [totalmoney, setTotalmoney] = useState([0]);
  const [money, setMoney] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [service, setService] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRowsBig, setSelectedRowsBig] = useState([]);
  const [selectedKeysBig, setSelectedKeysBig] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [nameStatus, setNameStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isEdit, setIsEdit] = useState({
    record: {},
    check: false,
  });

  const [bookingDetails, setBookingDetails] = useState([]);
  const [detailBooking, setDetailBooking] = useState({});
  const employeeList = employee?.map((e, i) => ({
    key: e.id,
    value: e.id,
    label: e.fullName,
  }));

  useEffect(() => {
    getDatabyId();
  }, [id]);

  const getDatabyId = () => {
    axios.get(`/booking/get/id?id=${id}`).then((response) => {
      formCustomer.setFieldsValue({
        customerName: response.data.data.customerName,
        phone: response.data.data.phone,
        mail: response.data.data.mail,
        innerNote: response.data.data.innerNote,
      });
      setBookingDetails(response.data.data.bookingDetailResponses);
      setDetailBooking(response.data.data);
      setDataTable(response.data.data.bookingDetailResponses);
      setTotalmoney(response.data.data.totalMoney)
    });
  };

  const FormatMoney = (value) => {
    let data = parseInt(value);
    return formatMoney(data);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  useEffect(() => {
    if (supplierId !== "") {
      getDataService();
      getEmployee();
    }
  }, []);

  const getDataService = () => {
    axios
      .get(`/service/get/supplier?supplier=${supplierId}&page=0&size=1000`)
      .then((response) => {
        setService(response?.data?.data);
      });
  };

  const getEmployee = () => {
    axios
      .get(`/employee/get?supplierId=${supplierId}&page=0&size=1000`)
      .then((response) => {
        setEmployee(response?.data?.data);
      });
  };

  const onUpdate = (value) => {
    const body = {
      ...detailBooking,
      ...value,
    };
    dispatch(
      addBooking(userId, body, {
        onSuccess: (res) => {
          if (res?.data?.data?.status?.statusCode == 200) {
            notification.success({
              message: `Chỉnh sửa thông tin khách hàng thành công`,
            });
          } else {
            notification.error({
              message: !!res?.data?.data?.status?.message
                ? res?.data?.data?.status?.message
                : `Chỉnh sửa thông tin khách hàng thất bại`,
            });
          }
        },
        onError: () => {
          notification.error({
            message: `Thêm booking thất bại`,
          });
        },
      })
    );
  };

  const onChangeTimeReal = (i) => (date, dateString) => {
    let newArr = [...bookingDetails];
    const dateConvert = moment(`${dateString}`, "DD-MM-YYYY HH:mm").format(
      "YYYY-MM-DD HH:mm:00"
    );
    newArr[i].realTime = dateConvert;
    setBookingDetails(newArr);
  };

  const time = new Date();
  const hours = moment(time).format("HH");
  const minute = moment(time).format("mm");

  const disabledTime = (current) => {
    if (current < new Date()) {
      return {
        disabledHours: () => range(0, hours),
        disabledMinutes: () => range(0, minute),
      };
    }
  };

  const disabledDate = (current) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    const date = new Date(today);
    return current && current < date;
  };

  useEffect(() => {
    const money = totalmoney;
    setMoney(money);
  }, [totalmoney]);


  const handleChangePrice = (index, e) => {
    let newArr = [...bookingDetails];
    newArr[index].price = e;
    setBookingDetails(newArr);
  };

  const onShowUpd = (record) => {
    setIsEdit({
      record,
      check: true,
    });
  };

  const onChangeEmployee = (index, data) => {
    let newArr = [...bookingDetails];
    newArr[index].employeeId = data;
    newArr[index].employeeName = employeeList?.find(
      (item) => item.value == data
    )?.label;
    setBookingDetails(newArr);
  };

  const onSaveData = (record, bookingId) => {
    const body = {
      bookingId: bookingId,
      id: record.id,
      price: record?.price,
      realTime: record?.realTime,
      employeeId: record?.employeeId,
      employeeName: record?.employeeName,
    };
    dispatch(
      updateBookingDetail(body, {
        onSuccess: () => {
          notification.success({
            message: `Chỉnh sửa booking thành công`,
          });
          getDatabyId();
          setIsEdit({
            record: {},
            check: false,
          });
          getDatabyId();
        },
        onError: () => {
          notification.error({
            message: `Thêm booking thất bại`,
          });
        },
      })
    );
  };

  const onChangeRowSelectedData = (key, rows) => {
    console.log(key);
    setSelectedRows(rows);
    setSelectedKeys(key);
  };

  const columnsChildren = (bookingId) => {
    return [
      {
        title: "Tên dịch vụ",
        dataIndex: "serviceUserName",
        key: "serviceUserName",
        ellipsis: {
          showTitle: false,
        },
        width: 150,
        render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            <b style={{ color: "#697689" }}>{value}</b>
          </Tooltip>
        ),
      },

      {
        title: "Trạng thái dịch vụ",
        dataIndex: "status",
        key: "status",
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (value) => (
          <span
            id="check-color"
            style={{
              borderRadius: 5,
              color:
                value === "Đang chờ"
                  ? "#0693E3"
                  : value === "Đang phục vụ"
                  ? "#00d084"
                  : value === "Đã phục vụ"
                  ? "#607D8B"
                  : "#f47373",
              padding: 4,
              fontWeight: "bold",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        title: "Thời gian cập nhật",
        dataIndex: "modifiedDate",
        key: "modifiedDate",
        ellipsis: {
          showTitle: false,
        },
        width: 150,
        responsive: ["lg", "xs", "sm", "md", "xl"],
        render: (value) => (
          <Tooltip
            placement="topLeft"
            title={moment(value).format("DD/MM/YYYY - HH:mm")}
          >
            <span> {moment(value).format("DD/MM/YYYY - HH:mm")}</span>
          </Tooltip>
        ),
      },

      {
        title: "Giá dịch vụ",
        dataIndex: "price",
        key: "price",
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: (text, record, index) => (
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {!(isEdit.check && isEdit.record.id == record.id) ? (
              `${record.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ) : (
              <InputNumber
                disabled={!(isEdit.check && isEdit.record.id == record.id)}
                defaultValue={record.price}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                onChange={(e) => {
                  handleChangePrice(index, e);
                }}
                style={{ minWidth: "150px" }}
                suffix={"VND"}
              />
            )}
          </div>
        ),
      },

      {
        title: "Khoảng giá",
        dataIndex: "khoangGia",
        key: "khoangGia",
        ellipsis: {
          showTitle: false,
        },
        width: 250,
        responsive: ["lg", "xs", "sm", "md", "xl"],
        render: (value, r) => (
          <Tooltip
            placement="topLeft"
            title={`${FormatMoney(r.minPrice)} - ${FormatMoney(
              r.maxPrice
            )} VND`}
          >
            <span>
              {" "}
              {`${FormatMoney(r.minPrice)} - ${FormatMoney(r.maxPrice)} VND`}
            </span>
          </Tooltip>
        ),
      },

      {
        title: "Thời gian dự kiến",
        dataIndex: "intendTime",
        key: "intendTime",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["lg", "xs", "sm", "md", "xl"],
        width: 150,
        render: (value) => (
          <Tooltip
            placement="topLeft"
            title={moment(value).format("DD-MM-YYYY HH:mm")}
          >
            <span> {moment(value).format("DD-MM-YYYY HH:mm")}</span>
          </Tooltip>
        ),
      },
      {
        title: "Thời gian thực tế",
        dataIndex: "realTime",
        key: "realTime",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["lg", "xs", "sm", "md", "xl"],
        width: 200,
        render: (t, r, i) => (
          <div gutter={24}>
            {
              <Tooltip
                placement="topLeft"
                title={moment(
                  !!r?.realTime ? r?.realTime : r?.intendTime
                ).format("DD-MM-YYYY HH:mm")}
              >
                <span>
                  {" "}
                  {moment(!!r?.realTime ? r?.realTime : r?.intendTime).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </span>
              </Tooltip>
            }
          </div>
        ),
      },
      {
        title: "Nhân viên",
        dataIndex: "employeeName",
        key: "employeeName",
        ellipsis: {
          showTitle: false,
        },
        width: 200,
        render: (t, r, i) =>
          !(isEdit.check && isEdit.record.id == r.id) ? (
            <Tooltip placement="topLeft" title={r?.employeeName}>
              <b style={{ color: "#697689" }}>{r?.employeeName}</b>
            </Tooltip>
          ) : (
            <div className="selectCustomer">
              <CustomSelect
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                placeholder="Chọn nhân viên"
                options={employeeList}
                onChange={(e) => {
                  onChangeEmployee(i, e);
                }}
              />
            </div>
          ),
      },
      {
        title:
          nameStatus === "Đang chờ" || nameStatus === "Đang phục vụ"
            ? "Chỉnh sửa"
            : "",
        dataIndex: "edit",
        key: "edit",
        ellipsis: {
          showTitle: false,
        },
        fixed: "right",
        responsive: ["lg", "xs", "sm", "md", "xl"],
        width: 80,
        render: (text, record, index) => (
          <div>
            {record.status != "Đã xuất hoá đơn" &&
              record.status != "Đã hủy" &&
              !(isEdit.check && isEdit.record.id == record.id) && (
                <Popover content={<h4> Sửa thông tin</h4>}>
                  <EditOutlined
                    style={{
                      background: "rgb(55, 181, 157)",
                      borderRadius: 3,
                      fontSize: "12px",
                      color: "white",
                      padding: 5,
                      border: "1px solid rgb(55, 181, 157)",
                    }}
                    onClick={() => onShowUpd(record)}
                  />
                </Popover>
              )}
            {isEdit.check && isEdit.record.id == record.id && (
              <div style={{ display: "flex", gap: "10px" }}>
                <Popover content={<h4> Sửa thông tin</h4>}>
                  <SaveOutlined
                    style={{
                      background: "#2a7aec",
                      borderRadius: 3,
                      fontSize: "12px",
                      color: "white",
                      padding: 5,
                      border: "1px solid #2a7aec",
                    }}
                    onClick={() => onSaveData(record, bookingId)}
                  />
                </Popover>
                <Popover content={<h4> Huỷ</h4>}>
                  <CloseCircleOutlined
                    style={{
                      background: "rgb(250 45 80)",
                      borderRadius: 3,
                      fontSize: "12px",
                      color: "white",
                      padding: 5,
                      border: "1px solid rgb(250 45 80)",
                    }}
                    onClick={() => {
                      setIsEdit({
                        record: {},
                        check: false,
                      });
                    }}
                  />
                </Popover>
              </div>
            )}
          </div>
        ),
      },
    ];
  };

  return (
    <>
      <Row span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <b>
              <Link style={{ color: "#808080" }} to={"/booking"}>
                {" "}
                <TableOutlined /> Danh sách Booking
              </Link>
            </b>
          </Breadcrumb.Item>
          <div style={{ color: "black" }}>
            <b>Chi tiết booking</b>
          </div>
        </Breadcrumb>
      </Row>
      <br />

      <Form
        layout="vertical"
        form={formCustomer}
        onFinish={onUpdate}
        labelCol={{ xs: 6, sm: 6, md: 6, lg: 8, xl: 6, xxl: 6 }}
        wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}
      >

            <Form.Item>
              <h2 style={{ fontWeight: "bold" }}>Thông tin người booking</h2>
            </Form.Item>
            <Row gutter={[16, 16]} style={{ width: "100%" }}>
              <Col span={24} style={{ borderBottom: "1px solid #d9d9d9" }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      style={{ fontWeight: "500" }}
                      label={<div>Họ tên</div>}
                      name="customerName"
                      rules={[
                        { required: true, message: "Nhập tên Thành viên" },
                      ]}
                    >
                      <Input placeholder="Tên Thành viên" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      style={{ fontWeight: "500" }}
                      label="Điện thoại"
                      name="phone"
                      rules={[
                        { required: true, message: "Nhập số điện thoại" },
                      ]}
                    >
                      <Input
                        placeholder="Số điện thoại"
                        maxLength={10}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      style={{ fontWeight: "500" }}
                      label="Email"
                      name="mail"
                    >
                      <Input placeholder="Nhập Email" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      style={{ fontWeight: "500" }}
                      label="Ghi chú"
                      name="innerNote"
                    >
                      <TextArea placeholder="Ghi chú" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item style={{ fontWeight: "500" }} label="Tổng tiền">
                        <Input
                          value={FormatMoney(money) + "VND"}
                          disabled
                          style={{ fontWeight: "bold", color: "#0693E3" }}
                          placeholder="Tổng tiền"
                        />
                    </Form.Item>
                  </Col>
                  {/* <Col  xs={24}
                  sm={24}
                  md={12}
                  lg={4}
                  xl={3}
                  xxl={3}> */}
                  <Form.Item style={{ display: "flex", alignItems: "end" }}>
                    <Button
                      style={{
                        marginLeft: "6px",
                        backgroundColor: "rgb(55, 181, 157)",
                        border: "rgb(55, 181, 157)",
                      }}
                      type="primary"
                      htmlType="submit"
                    >
                      Lưu
                    </Button>
                  </Form.Item>
                  {/* </Col> */}
                </Row>
              </Col>

            
            </Row>



            <Form form={formBooking} autoComplete="off" >
                <h2 style={{ fontWeight: "bold" }}>Thông tin dịch vụ</h2>
                <Table
                  columns={columnsChildren(detailBooking.id)}
                  dataSource={detailBooking?.bookingDetailResponses}
                  size="small"
                  pagination={false}
                  scroll={{ x: "calc(400px + 50%)" }}
                />
            </Form>

            <div style={{margin:'20px 0'}}>
              <Link to={"/booking"}>
                <Button size="small">Thoát</Button>
              </Link>
            </div>
      </Form>
    </>
  );
};
export default UpdateBooking;
